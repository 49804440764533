export const mockLiveExperts = {
  "data": {
    "experts": [
      {
        "id": 1001,
        "image": "images/picture-1.jpg",
        "videoId": "o7ZNtcawSmQ"
      }, {
        "id": 1002,
        "image": "images/picture-2.jpg",
        "videoId": "SKqa1Fa6Gto"
      }, {
        "id": 1003,
        "image": "images/picture-3.jpg",
        "videoId": "RHzsWy7Fxbo"
      }
    ]
  }
}